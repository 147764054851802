import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const UserComp = ({ onLoad }) => {
  const [title, setTitle] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [backColor, setBackColor] = useState("");
  const getColorByLetter = (letter) => {
    // Ensure the input is a single character and uppercase
    if (letter.length !== 1 || !/[A-Z]/.test(letter)) {
      throw new Error("Input must be a single letter from A-Z");
    }

    const base = "A".charCodeAt(0); // ASCII for 'A'
    const asciiValue = letter.charCodeAt(0);
    const seed = (asciiValue - base) / 25;

    // Adjust these base values to ensure the colors are bright
    const minColorValue = 100; // Minimum value to avoid dark colors
    const colorRange = 155; // Adjusted range to keep colors light

    // Generate color components based on the seed and ensure they are bright
    const red = Math.floor(
      minColorValue + ((seed * colorRange) % (255 - minColorValue))
    );
    const green = Math.floor(
      minColorValue + ((seed * 2 * colorRange) % (255 - minColorValue))
    );
    const blue = Math.floor(
      minColorValue + ((seed * 3 * colorRange) % (255 - minColorValue))
    );

    // Convert the color components to a hex color string
    const color = `#${red.toString(16).padStart(2, "0")}${green
      .toString(16)
      .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;

    return color;
  };

  useEffect(() => {
    //read user cookie
    let user = Cookies.get("DASH_USER");
  
    if (user) {
      user = JSON.parse(user);
      let displayName = user.name ?? user.user;

      setDisplayName(displayName);
      //title is name first letter
      let firstLetter = displayName.charAt(0).toUpperCase();
      setTitle(firstLetter);
      //set random color
      let colors = ["#FFC107", "#007BFF", "#28A745", "#DC3545"];
      let rand = Math.floor(Math.random() * 4);
      setBackColor(getColorByLetter(firstLetter));
      onLoad && onLoad(displayName);
    }
  }, []);
  return (
    <div
      className="userComp d-flex justify-content-center align-items-center"
      style={{ backgroundColor: backColor }}
    >
      {title}
    </div>
  );
};

export default UserComp;
