import React from "react";
import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import { observer } from "mobx-react-lite";
import { state } from "../../state/stateManager";
import { useEffect, useState } from "react";
import { da } from "@faker-js/faker";

const OrdersData = observer(
  ({
    text,
    amount,
    hour,
    count,
    css,
    dailyAverage,
    postFix = "",
    isShowFunc = () => {
      return { show: true };
    },
  }) => {
    const [showFunResult, setshowFunResult] = React.useState(isShowFunc());
    const [diffPercent, setDiffPercent] = React.useState(null);
    const [yesterdayDWHGMV, setYesterdayDWHGMV] = useState(0);
    const [yesterdayDWHCount, setYesterdayDWHCount] = useState(0);
    const [dwhData, setDwhData] = useState(null);
    useEffect(() => {
      if (dailyAverage !== undefined) {
        //calculate difference between daily average and amount in percentage
        let diff = amount - dailyAverage;
        let percent = (diff / dailyAverage) * 100;
        setDiffPercent(percent);
      }
    }, [dailyAverage]);

    useEffect(() => {
      if (state.yesterdayGMV && state.yesterdayGMV.success) {
        setDwhData(state.yesterdayGMV.data);
        setYesterdayDWHGMV(state.yesterdayGMV.data.TotalGMV);
        setYesterdayDWHCount(state.yesterdayGMV.data.TotalCount);
      }
    }, [state.yesterdayGMV]);
    return (
      <GeneralBox
        dPadding="2"
        hasBorder={true}
        hasBackground={true}
        css={"order-sales-info mb-2 yesterday-container d-flex " + css}
      >
        <div className="d-flex" style={{ flexGrow: "1" }}>
          <div className="dash-yesterday-content">
            <div className="d-flex flex-column" style={{ height: "100%" }}>
              <div className="d-flex dash-side-header">
                <i className="bi bi-coin"></i>
                <div>
                  {text} {hour !== undefined ? `(${hour})` : ""}
                </div>
              </div>
              {!showFunResult.show ? (
                <div className="d-flex flex-wrap order-data-custom-text">
                  {showFunResult.text}
                </div>
              ) : (
                <>
                  <div className="dash-yesterday-amount d-flex flex-wrap">
                    <NumericFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      suffix={postFix}
                    />

                    <div
                      data-hidden={!diffPercent}
                      data-positive={diffPercent >= 0}
                      className="d-flex align-items-center yesterday-avg-diff"
                    >
                      <i
                        style={{ marginLeft: "5px" }}
                        className={
                          "bi " +
                          (diffPercent >= 0
                            ? "bi-caret-up-fill"
                            : "bi-caret-down-fill")
                        }
                      >
                        {" "}
                      </i>
                      {diffPercent && diffPercent.toFixed(2) + "%"}
                    </div>
                  </div>
                  <div className="dash-yesterday-count">
                    <NumericFormat
                      value={count}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                    />{" "}
                    <span className="ms-1"> orders</span>
                  </div>
                </>
              )}
            </div>

            <div>
              <div className="dwh-yesteray-breackdown">
                <div style={{ fontSize: "13px", marginBottom: "5px" }}>
                  <span>DWH GMV:</span>
                  <span
                    className="highlight-amount"
                    style={{ marginLeft: "5px" }}
                  >
                    {yesterdayDWHGMV > 0 ? (
                      <NumericFormat
                        value={yesterdayDWHGMV}
                        decimalScale={0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        suffix={postFix}
                      />
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                <div style={{ fontSize: "13px", marginBottom: "5px" }}>
                  <span>DWH COUNT:</span>
                  <span
                    className="highlight-amount"
                    style={{ marginLeft: "5px" }}
                  >
                    {yesterdayDWHGMV > 0 ? (
                      <NumericFormat
                        value={yesterdayDWHCount}
                        decimalScale={0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                        suffix={postFix}
                      />
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                <div className="dwh-bd-row-seperator" />
                <div className="dwh-bd-row">
                  <span>Global-e</span>
                  <div className="dwh-sumcount">
                    <span className="highlight-amount">
                      {dwhData ? (
                        <NumericFormat
                          value={dwhData?.gmv.GE_GMV_Sum}
                          decimalScale={0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          suffix={postFix}
                        />
                      ) : (
                        "N/A"
                      )}
                    </span>
                    <span>{dwhData?.count.Count_ApprovedOrders_GE}</span>
                  </div>
                </div>
                <div className="dwh-bd-row">
                  <span>Markets Pro</span>
                  <div className="dwh-sumcount">
                    <span className="highlight-amount">
                      {dwhData ? (
                        <NumericFormat
                          value={dwhData?.gmv.Flow_GMV_Sum}
                          displayType={"text"}
                          decimalScale={0}
                          thousandSeparator={true}
                          prefix={"$"}
                          suffix={postFix}
                        />
                      ) : (
                        "N/A"
                      )}
                    </span>
                    <span>{dwhData?.count.Count_ApprovedOrders_Flow}</span>
                  </div>
                </div>
                <div className="dwh-bd-row">
                  <span>Borderfree</span>
                  <div className="dwh-sumcount">
                    <span className="highlight-amount">
                      {dwhData ? (
                        <NumericFormat
                          decimalScale={0}
                          value={dwhData?.gmv.BF_GMV_Sum}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          suffix={postFix}
                        />
                      ) : (
                        "N/A"
                      )}
                    </span>
                    <span>{dwhData?.count.Count_ApprovedOrders_BF}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GeneralBox>
    );
  }
);

export default OrdersData;
