import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import release_notes from "../../assets/release_notes.json";
import TopMenu from "../TopMenu";
import Button from "@mui/material/Button";
import UserComp from "./UserComp";
import StockMiniWidget from "./StockMini";
const Header = observer(
  ({
    onHeaderClick,
    onPageNavigation,
    onResetView,
    isFullScreen = false,
    enableViewResetButton = false,
  }) => {
    const scrollerRef = useRef(null);
    const [isExpended, setIsExpended] = useState(false);
    const [version, setVersion] = useState(null);
    const [displayName, setDisplayName] = useState("");

    const onUserParsed = (displayName) => {
      setDisplayName(" | " + displayName);
    };
    useEffect(() => {
      setInterval(() => {
        scrollerRef?.current?.scrollToBottom();
      }, 100);

      if (release_notes.currentVersion) {
        setVersion(release_notes.currentVersion);
        window.document.title = `Global-e Dashboard ${release_notes.currentVersion}`;
      } else {
        setVersion("3.0.0A");
      }

 
    }, []);

    const onPageNavigate = (page) => {
      if (onPageNavigation) {
        onPageNavigation(page);
      }
    };
    const onClick = () => {
      onHeaderClick();
    };
    return (
      <div onClick={onClick} className="dv2-header d-flex">
        <div className="d-flex align-items-center">
          <TopMenu
            onPageNavigation={onPageNavigate}
            isFullScreen={isFullScreen}
          >
            <UserComp onLoad={onUserParsed} />
          </TopMenu>
          <span className="dashboard-menu-title">
            Global-e Dashboard{" "}
            <span style={{ fontSize: "11px" }}>{version}</span> {displayName}
          </span>
        </div>
        <div className="ticker_contaner" style={{display:"flex", alignItems:"center" }}>
          <div>
            <StockMiniWidget />
            </div>
        <div className="reset-view-container">
          {enableViewResetButton && (
            <Button
              variant="outlined"
              style={{ minWidth: "140px" }}
              onClick={onResetView}
            >
              CLOSE
            </Button>
          )}
        </div>
        </div>
      </div>
    );
  }
);

export default Header;
